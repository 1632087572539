/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { isMobile, isChrome, isSafari, isEdge, isFirefox, isIOS, isAndroid } from 'react-device-detect'
import { useEventListener } from 'vspry-hooks'
import { ConfirmSwal, InfoSwal } from 'vspry-style-components'

import useBrowserStorage from 'hooks/browserStorage'
import { useState } from 'react'
import { translateContextless } from 'context/localeContext'

export const getHelpContent = () => {
    if (isMobile) {
        if (isChrome) return translateContextless('swal.installHelp.mobile.chrome')
        if (isSafari) return translateContextless('swal.installHelp.mobile.safari')
        if (isEdge) return translateContextless('swal.installHelp.mobile.edge')
        if (isFirefox) return translateContextless('swal.installHelp.mobile.firefox')
    }
    return translateContextless('swal.installHelp.desktop.chrome')
}

const askFrequency = 1000 * 60 * 60 * 24 * 30

const deviceSpecificInstall = (prompt: () => void) => {
    if (isIOS) InfoSwal.fire({ title: translateContextless('swal.installHelp.title'), text: translateContextless('swal.installHelp.mobile.safari') })
    else if (isAndroid && window.configuration['PLAY_STORE_APP_ID']) {
        window.location.href = `http://play.google.com/store/apps/details?id=${window.configuration['PLAY_STORE_APP_ID']}`
    } else prompt()
}

export default function AppInstaller() {
    const [lastAsked, setLastAsked] = useBrowserStorage('pwa-install', 0)
    const [prompt, setPrompt] = useState<any | null>(null)

    const handleInstallPrompt = async (e: any) => {
        setPrompt(e)
        if (lastAsked > Date.now() - askFrequency || window.matchMedia('(display-mode: standalone)').matches) return
        e.preventDefault()
        const result = await ConfirmSwal.fire({
            title: translateContextless('swal.installHelp.title'),
            text: translateContextless('swal.installHelp.text'),
        })
        if (result.value) deviceSpecificInstall(e.prompt)
        setLastAsked(Date.now())
    }

    useEventListener('beforeinstallprompt', handleInstallPrompt)
    useEventListener('refireinstallprompt', () =>
        deviceSpecificInstall(() =>
            prompt
                ? prompt.prompt()
                : InfoSwal.fire({
                      title: translateContextless('swal.installHelp.title'),
                      text: translateContextless('swal.installHelp.mobile.chrome'),
                  })
        )
    )

    return null
}
